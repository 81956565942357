import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import useDataStore from '../../services/DataService';
import useNavigationStore from '../../services/NavigationService';
import Work from '../Work';
import { Container, Tags, Tag, Thumb, ThumbGrid, ThumbImg, ButtonContainer, Nextbutton, TagBundle, NextbuttonWrapper, AllbuttonWrapper } from './styles';

const tagTitles = {
  'painting' : 'Paintings',
  'onpaper' : 'Works on Paper',
  'mural' : 'Murals',
  'abstract' : 'Abstract',
  'figurative' : 'Figurative',
  'selection' : 'Selection',
  'all' : 'All',
};

export default function Works() {
  const artworks = useDataStore(state => state.artworks);

  const location = useLocation();

  const [tags, showArtworkId, showArtwork] = useMemo(()=>{
    const search = location.search;
    let [allTags, showArtworkId] = search.split("/");
    showArtworkId = parseInt(showArtworkId);
    allTags = allTags.substring(1).split(",");
    
    let showArtwork = undefined;
    if (artworks && showArtworkId) showArtwork = useDataStore.getState().getArtworkById(showArtworkId);
    return [allTags, showArtworkId, showArtwork];
  }, [location, artworks]);

  const filteredArtworks = useMemo(()=>
    artworks ? artworks.filter(a => a.tags.includes(tags[0]) && a.tags.includes(tags[1]) && (tags[2] === 'all' || a.tags.includes(tags[2]))) : undefined
  , [artworks, tags]);

  const returnToOverview = () => { useNavigationStore.getState().setShowArtwork(undefined) };
  
  const renderTags = (rtags, index) => rtags.map((t, i) => {
    const nuTags = [...tags];
    nuTags[index] = t;
    const link = '/work?' + nuTags.join(',');
    return <Tag key={i} light={!tags.includes(t)}><Link to={link}>{tagTitles[t]}</Link></Tag>
  });

  const getNextArtworkId = useMemo(() => {
    if (!filteredArtworks || !showArtworkId) return undefined;
    const currentIndex = filteredArtworks.findIndex(a => a.id === showArtworkId);
    const nextIndex = (currentIndex + 1) % filteredArtworks.length;
    const nextId = filteredArtworks[nextIndex].id;
    return nextId;
  }, [filteredArtworks, showArtworkId]);

  const renderThumbs = () => filteredArtworks.map((a, i) => <Thumb key={tags.join() + a.id + i}><Link to={'/work?' + tags.join(',') + '/' + a.id}><ThumbImg src={a.imageThumb} /></Link></Thumb>);

  if (showArtwork) {
    return (
      <Container>
        <Tags>
          <TagBundle><Tag><Link to={'/work?' + tags.join(',')}>Works</Link></Tag> / </TagBundle>
          <TagBundle>{renderTags(['painting', 'onpaper', 'mural'], 0)} / </TagBundle>
          <TagBundle>{renderTags(['figurative', 'abstract'], 1)} / </TagBundle>
          <TagBundle>{showArtwork.title_de}</TagBundle>
          <ButtonContainer>
            <AllbuttonWrapper><Link to={'/work?' + tags.join(',')}>All</Link></AllbuttonWrapper>
            {filteredArtworks.length > 1 && <NextbuttonWrapper><Link to={'/work?' + tags.join(',') + '/' + getNextArtworkId}>Next</Link></NextbuttonWrapper>}
          </ButtonContainer>
          {/* {filteredArtworks.length > 1 && (
            <Nextbutton>
              <Link to={'/work?' + tags.join(',') + '/' + getNextArtworkId}>Next</Link>
            </Nextbutton>
          )} */}
        </Tags>
        <Work work={showArtwork} key={showArtwork.id}/>
      </Container>
    );
  }

  return (
    <Container>
      <Tags>
        <TagBundle><Tag onClick={returnToOverview}>Works</Tag> / </TagBundle>
        <TagBundle>{renderTags(['painting', 'onpaper', 'mural'], 0)} / </TagBundle>
        <TagBundle>{renderTags(['figurative', 'abstract'], 1)} / </TagBundle>
        <TagBundle>{renderTags(['selection', 'all'], 2)}</TagBundle>
      </Tags>
      <ThumbGrid>
        {artworks && renderThumbs()}
      </ThumbGrid>
    </Container>
  );
}