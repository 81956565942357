import styled from 'styled-components';
import { mediaMax } from '../../common/breakpoints';
import { colors } from '../../styles';

export const ArrowRightVector = () => <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
<path d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
</svg>;

export const ArrowLeftVector = () => <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
<path d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
</svg>;

export const Container = styled.div`
  position: relative;
`;

export const Pagination = styled.div`
  text-align: center;
  margin-top: 10px;
`;

export const PaginationDot = styled.div`
  cursor: pointer;
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin: 1.5px;
  background-color: ${props => props.active ? colors.darkGray : colors.lightGray};
`;

export const ImgContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

export const SliderContainer = styled.div`
  position: relative;
  display: block;
  width: calc(100% + 12px);
  .react-swipe-container > div {
    display: flex;
    align-items: center;
  }
`;

export const ClickBox = styled.div`
  position: absolute;
  cursor: pointer;
  top: 0;
  width: 50%;
  height: 100%;
  left: ${props => props.right ? '50%' : '0'};
  opacity: 0;
  &:hover {
    opacity: 1;
  }
  transition: opacity 100ms linear;
`;

export const Arrow = styled.div`
  position: absolute;
  top: 50%;
  ${props => props.right ? 'right' : 'left'} : 0;
  cursor: pointer;
`;

export const Img = styled.img`
  width: 100%;
  max-height: 80vh;
  max-width: 1000px;
  object-fit: contain;
  object-position: center;
`;

export const SlideContainer = styled.div`
  margin-right: 12px;
  /* flex-shrink: 0; */
`;

export const SliderImg = styled.img`
  width: 100%;
  object-fit: contain;
  object-position: center;
`;
