import styled from 'styled-components';
import { mediaMax, mediaMinMax } from '../../common/breakpoints';
import { colors } from '../../styles';

export const Container = styled.div`
`;

export const Tags = styled.div`
  display: inline-block;
  position: relative;
  margin-bottom: 32px;
  width: 100%;
  ${mediaMax('small',`
    margin-bottom: 16px;
  `)}
  a {
    text-decoration: none;
    color: inherit;
  }
`;

export const TagBundle = styled.span`
  margin-right: 4px;
  ${mediaMax('small',`
    float: left;
    clear: both;
  `)}
`;

export const Tag = styled.span`
  color: ${props => props.light ? colors.lightGray : colors.darkGray};
  margin: 4px;
  &:first-child {
    margin-left: 0;
  }
  cursor: ${props => props.light ? 'pointer' : 'default'};
  &:hover{
    color: ${colors.darkGray};
  }
  transition: color .1s cubic-bezier(0.42, 0, 0.58, 1);
`;

export const ButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  /* cursor: pointer; */
`;

export const AllbuttonWrapper = styled.span`
  float: left;
  @media only screen and (max-width: 900px) {
    float: right;
  }
`;

export const NextbuttonWrapper = styled.span`
  float: right;
  margin-left: 4px;
  &:before {
    content: '/ ';
  }
  @media only screen and (max-width: 900px) {
    clear: both;
  }
  ${mediaMax('small',`
    &:before {
      content: '';
    }
    margin-top: 34px;
  `)}
`;

export const Nextbutton = styled.div`
  position: absolute;
  top: 24px;
  right: 0;
  cursor: pointer;
`;

export const ThumbGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 96px 96px;
  align-items: center;
  ${mediaMinMax('medium', 'medium',`
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 48px 48px;
  `)}
  ${mediaMax('small',`
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px 16px;
  `)}
`;

export const Thumb = styled.div`
  cursor: pointer;
`;

export const ThumbImg = styled.img`
  width: 100%;
`;

export const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  ${mediaMax('small',`
    display: block;
  `)}
`;

export const Img = styled.img`
  width: 100%;
  max-height: 1000px;
  max-width: 1000px;
  object-fit: contain;
  object-position: center;
`;
