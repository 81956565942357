import create from '../utilities/zustand/create';
import axios from 'axios';

const REACT_APP_BASE_URL = 'https://backend.stephanhohenthanner.com'
const API_URL = REACT_APP_BASE_URL + '/api';
const xApiKey = 'PrQ8TWfEB5kDCjwEW92oe';

const useDataStore = create(module, (set, get) => ({
  startwork: undefined,
  startwork_video: undefined,
  start_show_video: undefined,
  accesspass: undefined,
  accessfile: undefined,
  gallery_link: undefined,
  artworks: undefined,
  exhibitions: undefined,
  cv: undefined,
  contact: undefined,
  getAllArtworks: () => {
    axios({
      headers: {
        'x-api-key': xApiKey,
      },
      method: 'get',
      url: API_URL + '/artworks',
      }).then(res => {
        set({
          startwork: res.data.startwork,
          startwork_video: res.data.startwork_video,
          start_show_video: res.data.start_show_video,
          start_tags: res.data.start_tags,
          artworks: formatArtworks(res.data.artworks)
        });
      });
  },
  getArtworkById: id => {
    const { artworks } = get();
    const showArtwork = artworks.find(a => a.id === id);
    return showArtwork;
  },
  getExhibitions: () => {
    axios({
      headers: {
        'x-api-key': xApiKey,
      },
      method: 'get',
      url: API_URL + '/exhibitions',
      }).then(res => {
        set({exhibitions: res.data.exhibitions});
      });
  },
  getInfoblocks: () => {
    axios({
      headers: {
        'x-api-key': xApiKey,
      },
      method: 'get',
      url: API_URL + '/infoblocks',
      }).then(res => {
        set({cv: res.data.cv, contact: res.data.contact});
      });
  },
  getRest: () => {
    axios({
      headers: {
        'x-api-key': xApiKey,
      },
      method: 'get',
      url: API_URL + '/rest',
      }).then(res => {
        set({accesspass: res.data.access_pass, accessfile: res.data.access_file, gallery_link: res.data.gallery_link});
      });
  },
}));

const formatArtworks = (artworks) => {
  const newArtworks = [...artworks];
  for (let a = 0; a < newArtworks.length; a++) {
    newArtworks[a].tags = newArtworks[a].tags.split(' ');
  }
  return newArtworks;
}

export default useDataStore;
